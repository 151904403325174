import throttle from 'lodash/throttle';

const $compareBar = document.querySelector('.compare-bar-wrapper');
const maxSlots = document.querySelector('.compare-bar')
    ? parseInt(document.querySelector('.compare-bar').getAttribute('data-max-slots'), 10)
    : '';
let productsForComparison = [];

const $compareButton = document.querySelector('button.compare');
const $compareBarTitle = document.querySelector('.compare-bar--title');

const compareButtonText = $compareButton?.innerText;
const compareBarTitle = $compareBarTitle?.innerText;

/**
 * Sets the compare product list in local storage
 *
 */
function getCompareLocalData() {
    // Make sure local Storage is supported
    let compareProductData = false;

    if (typeof localStorage !== 'undefined') {
        // Get Compare Items from local Storage
        const localCompareData = localStorage.getItem('compareProducts');

        compareProductData = [];

        // If we had existing local data, try to parse it
        if (localCompareData) {
            try {
                compareProductData = JSON.parse(localCompareData);
            } catch (err) {
                console.error(err);
            }
        }
    }

    return compareProductData;
}

/**
 * Sets the compare product list in local storage
 *
 * @param {object} detail - compare product
 * @param {string} option - action option
 */
function setCompareStorage(detail, option) {
    // Make sure Local Storage is Supported
    const compareData = getCompareLocalData();
    if (compareData) {
        const { pid } = detail;
        const { cgid } = detail;

        // Build Compare List Object
        const compareObj = {
            cgid,
            productList: compareData.productList || [],
        };

        // Check category match to prevent mixed comparison
        if (compareObj.cgid === compareData.cgid) {
            // Remove Product
            if (option === 'deselected' && compareObj.productList.indexOf(pid) > -1) {
                compareObj.productList = compareObj.productList.filter(
                    (item) => item !== pid.toString()
                );
                // Add Product
            } else if (compareObj.productList.indexOf(pid) === -1) {
                compareObj.productList.push(pid);
            }
        } else {
            // Replace compare list with new list
            compareObj.productList = [pid];
        }

        localStorage.setItem('compareProducts', JSON.stringify(compareObj));
    }
}

/**
 * @typedef ProductComparisonList
 * @type Object
 * @property {string} pid - ID for product to compare
 * @property {string} imgSrc - Image URL for selected product
 */

/**
 * Compiles the HTML for a single slot
 *
 * @param {ProductComparisonList} product - Selected product to compare
 * @param {number} idx - Slot number (zero-based)
 * @return {string} - HTML for a single slot
 */
function compileSlot(product, idx) {
    const { pid } = product;
    const name = `pid${idx}`;
    let html;

    if (product.sourceObj) {
        // Action triggered from Compare Checkbox

        html = `<div class="selected-product">
        <div class="compare-bar__slot" data-pid="${pid}">
        <picture>
        <source srcset="${product.sourceObj.srcset}" sizes="${product.sourceObj.sizes}">
        <img alt="${product.imgObj.altText}" src="${product.imgObj.src}">
        </picture>
        <button type="button" class="close cta-circle cta-circle-light cta-close-x cta-close-x-light" aria-label="${product.resourceMsgObj.removeProduct}">
        <svg><use href="#icon--x"></use></svg>
        </button>
        </div>
        <input type="hidden" name="${name}" value="${pid}">
        </div>`;
    } else {
        // Action triggered getting compare products from Ajax call

        html = `<div class="selected-product">
        <div class="compare-bar__slot" data-pid="${pid}">
        <img alt="${product.imgObj.alt}" src="${product.imgObj.url}">
        <button type="button" class="close cta-circle cta-circle-light cta-close-x cta-close-x-light" aria-label="${product.resourceMsgObj}">
        <svg><use href="#icon--x"></use></svg>
        </button>
        </div>
        <input type="hidden" name="${name}" value="${pid}">
        </div>`;
    }

    return html;
}

/**
 * Draw and render the Compare Bar product slots
 *
 * @param {ProductComparisonList []} productsToCompare - List of ID's of the products to compare
 */
function redrawCompareSlots(productsToCompare) {
    let html = productsToCompare.map((product, idx) => compileSlot(product, idx)).join('');

    // Render empty slots
    if (productsToCompare.length < maxSlots) {
        const numAvailableSlots = maxSlots - productsToCompare.length;
        const productImgMsg = document
            .querySelector('.compare-bar')
            .getAttribute('data-product-img-msg');

        for (let i = 0; i < numAvailableSlots; i += 1) {
            html += `<div class="selected-product"><button class="compare-bar__slot compare-bar__slot--empty"><span class="selected-product--msg">${productImgMsg}</span></button></div>`;
        }
    }

    document.querySelector('.compare-bar .product-slots').innerHTML = html;
}

/**
 * Enables/disables the Compare button, depending on whether at least two products have been
 * selected for comparison
 *
 * @param {number} numProducts - Number of products selected for comparison
 */
function setCompareButton(numProducts) {
    const numOfProducts = `(${numProducts})`;
    if (numProducts > 0) {
        $compareBarTitle.innerText = `${compareBarTitle} ${numOfProducts}`;
        $compareButton.innerText = `${compareButtonText} ${numOfProducts}`;
    } else {
        $compareBarTitle.innerText = compareBarTitle;
        $compareButton.innerText = compareButtonText;
    }
    if (numProducts < 2) {
        $compareButton.setAttribute('disabled', true);
    } else {
        $compareButton.removeAttribute('disabled');
    }
}

/**
 * Returns a copy of a list of products to compare
 *
 * @param {ProductComparisonList []} productsToCompare - List of ID's of the products to compare
 * @return {ProductComparisonList []} List of ID's of the products to compare
 */
function copyProducts(productsToCompare) {
    return productsToCompare.map((product) => {
        const proxy = {};

        Object.keys(product).forEach((key) => {
            proxy[key] = product[key];
        });

        return proxy;
    });
}

/**
 * Toggles between enabled/disabled compare checkboxes on product tiles
 *
 * @param {string} action - Enabled/Disabled action
 */
function toggleDisableCompareCheckbox(action) {
    if (action === 'disabled') {
        document
            .querySelectorAll('.compare input[type=checkbox]:not(:checked)')
            .forEach((input) => input.setAttribute('disabled', true));
    } else {
        document
            .querySelectorAll('.compare input[type=checkbox]:not(:checked)')
            .forEach((input) => input.removeAttribute('disabled'));
    }
}

/**
 * Handles the selection of a product for comparison
 *
 * @param {ProductComparisonList []} products - List of ID's of the products to compare
 * @param {string} pid - ID for product to compare
 * @param {string} sourceObj - Source Element Object containing srcset and sizes
 * @param {string} imgObj - Image Element Object containing alt text and src
 * @param {string} resourceMsgObj - Resource Message Object containing messages for compare
 * @return {ProductComparisonList []} List of ID's of the products to compare
 */
function selectProduct(products, pid, sourceObj, imgObj, resourceMsgObj) {
    const productsToCompare = copyProducts(products) || [];

    if (productsToCompare.length < maxSlots) {
        productsToCompare.push({ pid, sourceObj, imgObj, resourceMsgObj });

        if (productsToCompare.length === maxSlots) {
            toggleDisableCompareCheckbox('disabled');
        }

        redrawCompareSlots(productsToCompare);
        setCompareButton(productsToCompare.length);

        $compareBar.classList.remove('d-none');
        $compareBar.classList.add('d-block');
    }

    return productsToCompare;
}

/**
 * Handles the deselection of a product
 *
 * @param {ProductComparisonList []} products - List of ID's of the products to compare
 * @param {string} pid - ID for product to compare
 * @return {ProductComparisonList []} List of ID's of the products to compare
 */
function deselectProduct(products, pid) {
    let productsToCompare = copyProducts(products) || [];

    productsToCompare = productsToCompare.filter((product) => product.pid !== pid);

    if (productsToCompare.length === 0) {
        $compareBar.classList.remove('d-block');
        $compareBar.classList.add('d-none');
    }

    if (document.getElementById(pid)) {
        document.getElementById(pid).checked = false;
    }

    toggleDisableCompareCheckbox('enabled');
    redrawCompareSlots(productsToCompare);
    setCompareButton(productsToCompare.length);

    return productsToCompare;
}

/**
 * Clears the Compare Bar and hides it
 * @return {undefined}
 */
function clearCompareBar() {
    const cgid = document.querySelector('input.category-id').value;

    productsForComparison.forEach((product) => {
        document.dispatchEvent(
            new CustomEvent('compare:deselected', {
                detail: {
                    pid: product.pid,
                    cgid,
                },
            })
        );
    });

    productsForComparison = [];
    document.querySelectorAll('.compare input').forEach((input) => {
        const compareInput = input;

        compareInput.checked = false;
    });

    toggleDisableCompareCheckbox('enabled');

    if ($compareBar?.classList.contains('d-block')) {
        $compareBar.classList.remove('d-block');
        $compareBar.classList.add('d-none');
    }
}

/**
 * Update URL to match current compare product params
 * @returns {String}
 */
function toggleStickyHeader() {
    const elementTitlePosition = document
        .querySelector('.product-tile__name')
        .getBoundingClientRect();
    const elTilePosTop = elementTitlePosition.top;
    const elTilePosBottom = elementTitlePosition.bottom;
    const compareStickyHeaderEl = document.querySelector(
        '.product-comparison__sticky-header--container'
    );

    if (
        (elTilePosTop > 0 && elTilePosTop < window.innerHeight) ||
        (elTilePosBottom > 0 && elTilePosBottom < window.innerHeight)
    ) {
        compareStickyHeaderEl.classList.add('keep-hidden');
    } else {
        compareStickyHeaderEl.classList.remove('keep-hidden');
    }
}

/**
 * Load locally stored compare list into compare bar
 */
function loadStoredCompareBar() {
    const url = document.querySelector('.compare-bar')?.dataset?.compareGetProductsUrl;
    const cgid = document.querySelector('input.category-id').value;
    const storedCompareProducts = getCompareLocalData();

    if (storedCompareProducts?.productList?.length > 0 && storedCompareProducts.cgid === cgid) {
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(storedCompareProducts),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                data.productList.forEach((product) => {
                    // Remove Unavailable product from stored list
                    if (!product.availability) {
                        document.dispatchEvent(
                            new CustomEvent('compare:deselected', {
                                detail: {
                                    pid: product.pid,
                                    cgid,
                                },
                            })
                        );
                    } else {
                        productsForComparison = selectProduct(
                            productsForComparison,
                            product.pid,
                            product.sourceObj,
                            product.imgObj,
                            product.resourceMsgObj
                        );
                    }
                });
            })
            .catch((error) => {
                console.error('Fetch error:', error);
            });
    }
}

/**
 * Selects products for comparison based on the checked status of the Compare checkboxes in
 * each product tile.  Used when user goes back from the Compare Products page.
 */
function selectCompareProductTiles() {
    const cgid = document.querySelector('input.category-id').value;
    const storedCompareProducts = getCompareLocalData();
    const compareBar = document.querySelector('.compare-bar');

    if (compareBar) {
        if (storedCompareProducts?.productList?.length > 0 && storedCompareProducts.cgid === cgid) {
            storedCompareProducts.productList.forEach((id) => {
                const productTile = document.getElementById(id);
                if (productTile) {
                    productTile.checked = true;
                }
            });
        }
    }
}

export default {
    /**
     * Initializes Compare Local Storage on Page Load
     */
    initPageLocalStorage: () => {
        const compareBar = document.querySelector('.compare-bar');
        const comparePage = document.querySelector('.product-comparison');

        if (compareBar) {
            loadStoredCompareBar();
            selectCompareProductTiles();
        } else if (comparePage) {
            const cgid = document.querySelector('[data-category-id]').dataset.categoryId;
            const compareProductsObj = {};

            // Clear local storage data - ensures data matches compare page
            if (localStorage) {
                localStorage.compareProducts = '';
            }

            // Build local storage data - match data with compare page
            // Allows customer service to send compare page to customers and save compare data to local storage
            document.querySelectorAll('.product-header').forEach((item) => {
                compareProductsObj.cgid = cgid;
                compareProductsObj.pid = item.dataset.compareProduct;

                setCompareStorage(compareProductsObj, 'selected');
            });
        }
    },

    /**
     * PLP Page Compare Functions
     */

    /**
     * Handles Compare Local Storage Actions
     */
    handleStorageActions: () => {
        document.addEventListener('compare:selected', (e) => {
            setCompareStorage(e.detail, 'selected');
        });

        document.addEventListener('compare:deselected', (e) => {
            setCompareStorage(e.detail, 'deselected');
        });
    },

    /**
     * Update Compare Checkbox on Product Tiles
     */
    selectCompareProductTiles: () => {
        document.addEventListener('UpdateSelectCompareProductTiles', () => {
            selectCompareProductTiles();

            if (productsForComparison.length === maxSlots) {
                toggleDisableCompareCheckbox('disabled');
            }
        });
    },

    /**
     * Handles Compare checkbox click
     */
    handleCompareClick: () => {
        document.addEventListener('click', (e) => {
            const target = e?.target?.closest('.compare input[type=checkbox]');

            if (target) {
                const pid = target.getAttribute('id');
                const cgid = document.querySelector('input.category-id').value;
                const checked = target.checked === true;
                const activeTile = target
                    .closest('.product-tile')
                    .querySelector('.product-tile__image.active');
                const tileImg = activeTile.querySelector('picture');
                const sourceObj = {
                    srcset: tileImg.querySelector('source').getAttribute('srcset'),
                    sizes: tileImg.querySelector('source').getAttribute('sizes'),
                };
                const imgObj = {
                    src: tileImg.querySelector('img').getAttribute('src'),
                    altText: tileImg.querySelector('img').getAttribute('alt'),
                };
                const resourceMsgObj = {
                    removeProduct: target.dataset.compareRemoveMsg,
                };

                if (checked) {
                    productsForComparison = selectProduct(
                        productsForComparison,
                        pid,
                        sourceObj,
                        imgObj,
                        resourceMsgObj
                    );
                    document.dispatchEvent(
                        new CustomEvent('compare:selected', {
                            detail: {
                                pid,
                                cgid,
                            },
                        })
                    );
                } else {
                    productsForComparison = deselectProduct(productsForComparison, pid);
                    document.dispatchEvent(
                        new CustomEvent('compare:deselected', {
                            detail: {
                                pid,
                                cgid,
                            },
                        })
                    );
                }
            }
        });
    },

    /**
     * Handles the Clear All link
     */
    handleClearAll: () => {
        document.querySelector('.compare-bar .clear-all')?.addEventListener('click', (e) => {
            e.preventDefault();
            clearCompareBar();
        });
    },

    /**
     * Handles deselection of a product on the Compare Bar
     */
    deselectProductOnCompareBar: () => {
        document.querySelector('.compare-bar')?.addEventListener('click', (e) => {
            const target = e?.target?.closest('.close');
            if (target) {
                const pid = target
                    .closest('.compare-bar__slot')
                    ?.getAttribute('data-pid')
                    ?.toString();
                const cgid = document.querySelector('input.category-id').value;

                productsForComparison = deselectProduct(productsForComparison, pid);
                document.dispatchEvent(
                    new CustomEvent('compare:deselected', {
                        detail: {
                            pid,
                            cgid,
                        },
                    })
                );
            }
        });
    },

    /**
     * Clear compare list on filter change
     */
    catchFilterChange: () => {
        document.querySelector('.has-filters')?.addEventListener('click', (e) => {
            const target = e?.target?.closest(
                'a.shopping-refinement__attr, .filter-refine-pills a'
            );

            if (target) {
                const categories = target.closest('.shopping-refinements__refinement--category');

                if (!categories) {
                    e.preventDefault();
                }
                clearCompareBar();
            }
        });
    },

    /**
     * Toggles the compare bar
     */
    handleCompareBarExpand: () => {
        document.addEventListener('click', (e) => {
            const target =
                e?.target?.closest('.compare-bar .compare-bar--toggle') ||
                e?.target?.closest('.compare-bar .compare-bar__slot--empty');

            if (target) {
                e.preventDefault();

                const compareBar = target.closest('.compare-bar-wrapper');
                const compareBarHeader = compareBar.querySelector('.compare-bar--header');
                const compareBarContent = compareBar.querySelector('.compare-bar--content');
                const compareToggle = compareBarHeader.querySelector('.compare-bar--toggle');
                const chevronIcon = compareToggle.querySelector('.compare-bar__chevron');

                if (chevronIcon.classList.contains('is-collapsed')) {
                    chevronIcon.classList.remove('is-collapsed');
                    compareBar.style.bottom = 0;
                    compareToggle.setAttribute('aria-expanded', true);
                    compareBarContent.setAttribute('aria-expanded', true);
                } else {
                    const compareBarHeight = compareBar.offsetHeight;
                    const compareBarHeaderHeight = compareBarHeader.offsetHeight;

                    compareToggle.setAttribute('aria-expanded', false);
                    compareBarContent.setAttribute('aria-expanded', false);
                    chevronIcon.classList.add('is-collapsed');
                    compareBar.style.bottom = `${
                        (compareBarHeight - compareBarHeaderHeight) * -1
                    }px`;
                }
            }
        });
    },

    /**
     * Sets the history.pushState for history.back() to work from the Compare Products page.
     */
    setPushState: () => {
        document.querySelector('.compare-products-form')?.addEventListener('submit', (e) => {
            const { target } = e;
            const url = new URL(window.location).toString();

            window.history.pushState({}, document.title, url);

            target.querySelector('input[name="backUrl"]').value = url;
            target.querySelector('input[name="cgid"]').value =
                document.querySelector('input.category-id').value;
        });
    },

    /**
     * Compare Page Functions
     */

    /**
     * Removes Product from Compare Products Page.
     */
    removeCompareProduct: () => {
        document.querySelectorAll('.js-remove-compare-product')?.forEach((element) => {
            element.addEventListener('click', (e) => {
                const target = e?.target
                    ?.closest('.product-header')
                    .getAttribute('data-compare-product');
                if (target) {
                    e.preventDefault();

                    // Update URL Params
                    const windowLocation = window.location;
                    const url = new URL(windowLocation.href);
                    const { searchParams } = url;
                    let backURL = searchParams.get('backUrl');
                    const paramArray = Array.from(searchParams);

                    for (let i = 0; paramArray.length > i; i += 1) {
                        if (paramArray[i][0].includes('pid') && paramArray[i][1] === target) {
                            searchParams.delete(paramArray[i][0]);
                            searchParams.set('backUrl', backURL);

                            // set updated backURL
                            backURL = searchParams.get('backUrl');

                            window.history.replaceState('UpdateComparePLP', null, backURL);
                            window.history.pushState('UpdateComparePage', null, url);
                        }
                    }

                    const cgid = document.querySelector('[data-category-id]').dataset.categoryId;
                    document.dispatchEvent(
                        new CustomEvent('compare:deselected', {
                            detail: {
                                pid: target,
                                cgid,
                            },
                        })
                    );

                    if (document.querySelector('.col-num-2')) {
                        // Remove Selected Product and return to compare page
                        $.spinner().start();

                        windowLocation.href = backURL;
                    } else {
                        // Remove Product Table Column
                        document
                            .querySelectorAll(`[data-compare-product="${target}"]`)
                            .forEach((compareProduct) => {
                                compareProduct.remove();
                            });

                        const numColumns = document.querySelectorAll('colgroup col').length;

                        document.querySelectorAll('.product-comparison').forEach((compareEl) => {
                            for (let i = 0; i < compareEl.classList.length; i += 1) {
                                const className = compareEl.classList[i];

                                if (className.startsWith('col-num-')) {
                                    compareEl.classList.remove(className);
                                    compareEl.classList.add(`col-num-${numColumns}`);
                                }
                            }
                        });

                        document.querySelectorAll('[colspan]').forEach((headerColumn) => {
                            headerColumn.setAttribute('colspan', numColumns);
                        });
                    }
                }
            });
        });

        // Navigates for Compare URL History Updates on Back/Forward Navigation
        window.onpopstate = function (event) {
            const state = event.state?.toLowerCase();
            if (state && state.includes('compare')) {
                window.location.reload();
            }
        };
    },

    /**
     * Toggles the comparison table accordion
     */
    handleCompareTableAccordion: () => {
        document.querySelectorAll('[id^="accordionToggle--"]')?.forEach((element) => {
            element.addEventListener('click', (e) => {
                e.preventDefault();

                const toggleClassList = element.querySelector('.icon').classList;

                if (!toggleClassList.contains('is-collapsed')) {
                    toggleClassList.add('is-collapsed');
                } else {
                    toggleClassList.remove('is-collapsed');
                }
            });
        });
    },

    /**
     * Event listener to toggle sticky header on scroll
     */
    headerScrollListener: () => {
        if (document.querySelector('.product-comparison')) {
            window.addEventListener(
                'scrollUpdate',
                throttle(toggleStickyHeader, 200, { leading: false, trailing: true }),
                { passive: true }
            );
        }
    },
};
